import React, { useState } from "react";
import Home from "./components/Home";
import Header from "./components/Header";
import "./assets/iPortfolio/assets/css/style.css";
import "./assets/iPortfolio/assets/vendor/aos/aos.css";
import "./assets/iPortfolio/assets/vendor/bootstrap/css/bootstrap.min.css";
import "./assets/iPortfolio/assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "./assets/iPortfolio/assets/vendor/boxicons/css/boxicons.min.css";
import "./assets/iPortfolio/assets/vendor/glightbox/css/glightbox.min.css";
import "./assets/iPortfolio/assets/vendor/swiper/swiper-bundle.min.css";
import "./assets/css/custom.css";

function App() {
  return (
    <>
      <Header />
      <Home />
    </>
  );
}

export default App;
