import React, { useState } from "react";

import Foto from "./../assets/images/myphoto.jpeg";

function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [activeNavLink, setActiveNavLink] = useState("#hero");

  const toggleNav = () => {
    const body = document.body;
    const navbar = document.getElementById("navbar");

    if (isNavOpen) {
      body.classList.remove("mobile-nav-active");
      navbar.classList.remove("active");
    } else {
      body.classList.add("mobile-nav-active");
      navbar.classList.add("active");
    }

    setIsNavOpen(!isNavOpen);
  };

  const handleNavLinkClick = (id) => {
    setActiveNavLink(id);
    toggleNav();
  };

  return (
    <div>
      <i
        className={`bi ${
          isNavOpen ? "bi-x" : "bi-list"
        } mobile-nav-toggle d-xl-none`}
        onClick={toggleNav}
      ></i>
      <header id="header">
        <div className="d-flex flex-column">
          <div className="profile">
            <img src={Foto} alt="" className="img-fluid rounded-circle" />
            <h1 className="text-light">
              <a href="/">Zulkifli Ahmad</a>
            </h1>
            <div className="social-links mt-3 text-center">
              {/* Social links */}
            </div>
          </div>

          <nav id="navbar" className="nav-menu navbar">
            <ul>
              <li>
                <a
                  href="#hero"
                  className={`nav-link scrollto ${
                    activeNavLink === "#hero" ? "active" : ""
                  }`}
                  onClick={() => handleNavLinkClick("#hero")}
                >
                  <i className="bx bx-home"></i> <span>Home</span>
                </a>
              </li>
              <li>
                <a
                  href="https://cybernautsid.zhulachmad.my.id"
                  target="_blank"
                  className={`nav-link scrollto ${
                    activeNavLink === "#blog" ? "active" : ""
                  }`}
                  onClick={() => handleNavLinkClick("#blog")}
                >
                  <i className="bx bx-world"></i> <span>Blog</span>
                </a>
              </li>
              <li>
                <a
                  href="#about"
                  className={`nav-link scrollto ${
                    activeNavLink === "#about" ? "active" : ""
                  }`}
                  onClick={() => handleNavLinkClick("#about")}
                >
                  <i className="bx bx-user"></i> <span>Tentang</span>
                </a>
              </li>
              <li>
                <a
                  href="#pendidikan"
                  className={`nav-link scrollto ${
                    activeNavLink === "#pendidikan" ? "active" : ""
                  }`}
                  onClick={() => handleNavLinkClick("#pendidikan")}
                >
                  <i className="bx bx-file-blank"></i> <span>Pendidikan</span>
                </a>
              </li>
              <li>
                <a
                  href="#resume"
                  className={`nav-link scrollto ${
                    activeNavLink === "#resume" ? "active" : ""
                  }`}
                  onClick={() => handleNavLinkClick("#resume")}
                >
                  <i className="bx bx-briefcase"></i>
                  <span>Pengalaman Kerja</span>
                </a>
              </li>
              <li>
                <a
                  href="#portfolio"
                  className={`nav-link scrollto ${
                    activeNavLink === "#portfolio" ? "active" : ""
                  }`}
                  onClick={() => handleNavLinkClick("#portfolio")}
                >
                  <i className="bx bx-book-content"></i> <span>Portofolio</span>
                </a>
              </li>
              <li>
                <a
                  href="#contact"
                  className={`nav-link scrollto ${
                    activeNavLink === "#contact" ? "active" : ""
                  }`}
                  onClick={() => handleNavLinkClick("#contact")}
                >
                  <i className="bx bx-envelope"></i> <span>Kontak</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <a
          href="#"
          className="
        back-to-top
        d-flex
        align-items-center
        justify-content-center
        active
      "
        >
          <i className="bi bi-arrow-up-short"></i>
        </a>
      </header>
    </div>
  );
}

export default Header;
