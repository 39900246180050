import Typed from "typed.js";
import { useState } from "react";
import { useEffect, useRef } from "react";
import { Card, Modal, Row, Col } from "react-bootstrap";

function ImageCard({ name, image }) {
  const [showModal, setShowModal] = useState(false);

  const handleModalShow = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);
  return (
    <>
      <Card.Img
        variant="top"
        src={image}
        style={{
          margin: 5,
          padding: 5,
          width: "auto",
          height: "250px",
          objectFit: "contain",
        }}
        onClick={handleModalShow}
      />
      <Modal show={showModal} onHide={handleModalClose} size="md" centered>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black", fontSize: "16px" }}>
            {name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={image}
            alt={name}
            style={{ width: "100%", height: "auto", objectFit: "cover" }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default function Home() {
  const portfolioDatas = [
    {
      label: "2019 | Company Profile Makassar Digital Valley | WordPress",
      src: process.env.PUBLIC_URL + "/portofolio-img/homepage.png",
      link: "https://makassardigitalvalley.id",
      width: 400,
      height: 250,
      tags: [{ value: "web", title: "Web" }],
    },
    {
      label:
        "2019 | Member Dashboard Makassar Digital Valley | Laravel 7.0 + Ajax + JQuery + MySQL/MariaDB",
      src: process.env.PUBLIC_URL + "/portofolio-img/member-dashboard.png",
      link: "https://member.makassardigitalvalley.id",
      width: 400,
      height: 250,
      tags: [{ value: "web", title: "Web" }],
    },
    {
      label:
        "2020 | Tanggap Covid-19 Makassar Digital Valley | HTML5 + CSS3 + TailwindCSS (Static Page)",
      src: process.env.PUBLIC_URL + "/portofolio-img/covid19.png",
      link: "https://makassardigitalvalley.id/tanggap-covid19",
      width: 400,
      height: 250,
      tags: [{ value: "web", title: "Web" }],
    },
    {
      label:
        "2019 | Admin Dashboard Makassar Digital Valley | Laravel 7.0 + Ajax + JQuery + MySQL/MariaDB",
      src: process.env.PUBLIC_URL + "/portofolio-img/admin-dashboard.png",
      link: "https://makassardigitalvalley.id/tanggap-covid19",
      width: 400,
      height: 250,
      tags: [{ value: "web", title: "Web" }],
    },
    {
      label: "2021 | Daihatsu Palopo - Car Dealer | WordPress",
      src: process.env.PUBLIC_URL + "/portofolio-img/daihatsu.png",
      link: "https://daihatsupalopo.com/",
      width: 400,
      height: 250,
      tags: [{ value: "web", title: "Web" }],
    },
    {
      label:
        "2019 | E-Library UIN | Laravel 5.7 + Ajax + JQuery + MySQL/MariaDB",
      src: process.env.PUBLIC_URL + "/portofolio-img/elibrary.png",
      link: "http://20.102.114.81/e_library_fix/public",
      width: 400,
      height: 250,
      tags: [{ value: "web", title: "Web" }],
    },

    {
      label: "2021 | DR Luck - Online Shop | Bootstrap4 + HTML5+CSS3",
      src: process.env.PUBLIC_URL + "/portofolio-img/drluck.png",
      link: "http://20.102.114.81/drluck.io/",
      width: 400,
      height: 250,
      tags: [{ value: "web", title: "Web" }],
    },
    {
      label:
        "2021 | Assyifa - Aplikasi  Kegiatan Koas FKIK UINAM | Android - iOS Platform",
      src: process.env.PUBLIC_URL + "/portofolio-img/assyifa.webp",
      link: "https://apps.apple.com/lk/app/assyifa-logbook/id1596175941",
      width: 400,
      height: 250,
      tags: [{ value: "mobileapp", title: "Assyifa" }],
    },
    {
      label:
        "2021 | CBT-DM - Aplikasi Terapi Konseling Diabetes Melitus | iOS Platform  ",
      src: process.env.PUBLIC_URL + "/portofolio-img/cbt-dm.png",
      link: "#",
      width: 400,
      height: 250,
      tags: [{ value: "mobileapp", title: "CBT-DM" }],
    },
    {
      label:
        "2022 | SELEBRASI - Aplikasi Presensi UIN Alauddin Makassar | Android - iOS Platform  ",
      src: process.env.PUBLIC_URL + "/portofolio-img/selebrasi.png",
      link: "#",
      width: 400,
      height: 250,
      tags: [{ value: "mobileapp", title: "SELEBRASI" }],
    },
    {
      label: "2022 | Anrong Apps - Diskominfo Kota Makassar  | Website  ",
      src: process.env.PUBLIC_URL + "/portofolio-img/anrong.png",
      link: "#",
      width: 400,
      height: 250,
      tags: [{ value: "webapp", title: "Anrong Apps" }],
    },
    {
      label: "2022 | Profil Instansi - Diskominfo Kota Tual  | Website  ",
      src: process.env.PUBLIC_URL + "/portofolio-img/diskominfo.png",
      link: "#",
      width: 400,
      height: 250,
      tags: [{ value: "webapp", title: "Website App" }],
    },
    {
      label: "2022 | Website Profil - RSUD Kota Tual  | Website  ",
      src: process.env.PUBLIC_URL + "/portofolio-img/rsud.png",
      link: "#",
      width: 400,
      height: 250,
      tags: [{ value: "webapp", title: "Website App" }],
    },
    {
      label:
        "2023 | SMH -  Aplikasi Edukasi Pencegahan Hipertensi  | Android  ",
      src: process.env.PUBLIC_URL + "/portofolio-img/smh.png",
      link: "#",
      width: 400,
      height: 250,
      tags: [{ value: "mobileapp", title: "Mobile App" }],
    },
    {
      label: "2023 | AkuBisa -  Aplikasi Belajar Bahasa Isyarat  | Android  ",
      src: process.env.PUBLIC_URL + "/portofolio-img/akubisa.png",
      link: "#",
      width: 400,
      height: 250,
      tags: [{ value: "mobileapp", title: "Mobile App" }],
    },
    {
      label: "2023 | Profil Instansi -  Inspektorat Kota Tual  | Website  ",
      src: process.env.PUBLIC_URL + "/portofolio-img/inspektorat.png",
      link: "#",
      width: 400,
      height: 250,
      tags: [{ value: "webapp", title: "Website App" }],
    },
    {
      label: "2023 | HafayuTV -  Muslim App  | Android - iOS Platform  ",
      src: process.env.PUBLIC_URL + "/portofolio-img/hafayu.png",
      link: "#",
      width: 400,
      height: 250,
      tags: [{ value: "mobileapp", title: "Mobile App" }],
    },
    {
      label: "2023 | Portal MBKM -  POLIPANGKEP | Website  ",
      src: process.env.PUBLIC_URL + "/portofolio-img/mbkm-web.png",
      link: "#",
      width: 400,
      height: 250,
      tags: [{ value: "webapp", title: "Website App" }],
    },
    {
      label: "2023 | Aplikasi Logbook MBKM -  POLIPANGKEP | Android  ",
      src: process.env.PUBLIC_URL + "/portofolio-img/mbkm-android.webp",
      link: "#",
      width: 400,
      height: 250,
      tags: [{ value: "mobileapp", title: "Mobile App" }],
    },
    {
      label: "2024 | MTQ Politeknik Nasional -  POLIPANGKEP | Website  ",
      src: process.env.PUBLIC_URL + "/portofolio-img/mtq.png",
      link: "#",
      width: 400,
      height: 250,
      tags: [{ value: "webapp", title: "Website App" }],
    },
  ];

  // Create Ref element.
  const el = useRef(null);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [
        "<span style='color:#fff;font-size:20px;'>Hello, i am an Software Developer</span>",
      ], // Strings to display
      typeSpeed: 50,
      backSpeed: 50,
      loop: true,
    });

    // Destropying
    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div>
      <div
        id="hero"
        className="d-flex flex-column justify-content-center align-items-center"
      >
        <div className="hero-container ">
          <h1>Welcome !</h1>
          <h4 className="text-white">
            <span ref={el} className="typing text-white"></span>
          </h4>
        </div>
      </div>
      <main id="main">
        <section id="about" className="about">
          <div className="container">
            <div className="section-title">
              <h2>Tentang</h2>
              <p>
                Saya seorang programmer dengan pengalaman lebih dari 4 tahun
                dengan role sebagai Fullstack Developer merancang, membuat dan
                mengembangkan, serta melakukan pengujian aplikasi, sebagai
                Mobile Developer aplikasi Android dan iOS dengan pengalaman
                lebih dari dari 3 tahun. Saya selalu tertarik dengan bidang IT,
                menyukai tantangan dan akan terus belajar untuk meningkatkan
                keahlian dan pengalaman.
              </p>
            </div>
          </div>
        </section>

        <section id="skills" className="skills section-bg">
          <div className="container">
            <div className="section-title">
              <h2>Keahlian</h2>
            </div>
            <div
              className="pt-4 pt-lg-0 content aos-init aos-animate"
              data-aos="fade-left"
            >
              <div className="row">
                <div className="col">
                  <ul>
                    <li>
                      <i className="bi bi-chevron-right"></i> Web
                      Programming(Fullstack)
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i> Mobile
                      Programming(Android & iOS Platform)
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i> Basic Network
                      Instalation - Configuration
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i> Basic Server
                      Instalation - Configuration
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="pendidikan" className="resume">
          <div className="container">
            <div className="section-title">
              <h2>Pendidikan</h2>
            </div>

            <div className="row">
              <div
                className="col-lg-12 aos-init aos-animate"
                data-aos="fade-up"
              >
                <div className="resume-item">
                  <h4>S1 Sistem Informasi</h4>
                  <h5>2014 - 2018</h5>
                  <p>
                    <em>Universitas Islam Negeri Alauddin Makassar</em>
                  </p>
                </div>
                <div className="resume-item">
                  <h4>Teknik Komputer dan Jaringan</h4>
                  <h5>2010 - 2013</h5>
                  <p>
                    <em>SMKN 1 Somba Opu</em>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="skills" className="skills section-bg">
          <div className="container">
            <div className="section-title">
              <h2>Tools</h2>
            </div>
            <div
              className="pt-4 pt-lg-0 content aos-init aos-animate"
              data-aos="fade-left"
            >
              <div className="row">
                <div className="col">
                  <ul>
                    <li>
                      <i className="bi bi-chevron-right"></i> Apache2/Nginx
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i> PHP/Laravel v.10++
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i> SvelteJS
                    </li>

                    <li>
                      <i className="bi bi-chevron-right"></i> Bootstrap5
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i> Tailwind CSS
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i> WordPress +
                      Elementor
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i> HTML5 + CSS 3
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i>
                      Javascript/Ajax/JQuery
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i> Dart/Flutter
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i> GitHub
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i> GitLab
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i> MySQL/MariaDB
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i> MikroTik
                      RouterBoard + Ubiquity/Unifi
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i> Windows / Linux
                      (Ubuntu) / MacOS (Operating Systems)
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="resume" className="resume">
          <div className="container">
            <div className="section-title">
              <h2>Pengalaman Kerja</h2>
            </div>

            <div className="row">
              <div
                className="col-lg-12 aos-init aos-animate"
                data-aos="fade-up"
              >
                <div className="resume-item">
                  <h4>IT Consultant - Fulltime</h4>
                  <h5>Januari 2021 - Sekarang</h5>
                  <p>
                    <em>Carina Solusi Digital</em>
                  </p>
                  <b>Deskripsi Pekerjaan</b>
                  <div className="row pt-4">
                    <div className="col">
                      <ul>
                        <li>
                          <i className="bi bi-chevron-right"></i>
                          Membuat/Menyusun RAB Pembuatan -Implementasi Sistem IT
                        </li>
                        <li>
                          <i className="bi bi-chevron-right"></i>Melakukan
                          Perancangan Sistem/Aplikasi
                        </li>
                        <li>
                          <i className="bi bi-chevron-right"></i>Melakukan
                          Pembuatan - Implementasi Sistem/Aplikasi
                        </li>
                        <li>
                          <i className="bi bi-chevron-right"></i>Membuat
                          Dokumentasi Pekerjaan/Manual Book Sistem/Aplikasi
                        </li>
                        <li>
                          <i className="bi bi-chevron-right"></i>Melakukan
                          Pengembangan dan Maintenance Sistem/Aplikasi
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="resume-item">
                  <h4>Software Developer - Freelancer</h4>
                  <h5>Oktober 2023 - Desember 2023</h5>
                  <p>
                    <em>PT Digital Nusantara Abadi</em>
                  </p>
                  <b>Deskripsi Pekerjaan</b>
                  <div className="row pt-4">
                    <div className="col">
                      <ul>
                        <li>
                          <i className="bi bi-chevron-right"></i>
                          Pembuatan Portal Website MBKM POLIPANGKEP
                        </li>
                        <li>
                          <i className="bi bi-chevron-right"></i>
                          Pembuatan Aplikasi Logbook Kegiatan MBKM POLIPANGKEP
                          Berbasis Android
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="resume-item">
                  <h4>IT Infrastructure - Fulltime</h4>
                  <h5>Maret 2019 - Desember 2020</h5>
                  <p>
                    <em>Makassar Digital Valley</em>
                  </p>
                  <b>Deskripsi Pekerjaan</b>
                  <div className="row pt-4">
                    <div className="col">
                      <ul>
                        <li>
                          <i className="bi bi-chevron-right"></i> Mengelola
                          Infrastruktur IT (Networking, CCTV, Sound Systems,
                          Fingerprint)
                        </li>
                        <li>
                          <i className="bi bi-chevron-right"></i> Membuat
                          Website Dashboard Member
                        </li>
                        <li>
                          <i className="bi bi-chevron-right"></i> Membuat Sistem
                          Reservasi/Kunjungan/Front Desk Officer
                        </li>
                        <li>
                          <i className="bi bi-chevron-right"></i> Re-Design Web
                          Profil Perusahaan
                        </li>
                        <li>
                          <i className="bi bi-chevron-right"></i> Mengelola
                          Database
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="resume-item">
                  <h4>Web Developer - Freelancer</h4>
                  <h5>Desember 2018 - Februari 2019</h5>
                  <p>
                    <em>PT Bahana Cipta Teknologi</em>
                  </p>
                  <b>Deskripsi Pekerjaan</b>
                  <div className="row pt-4">
                    <div className="col">
                      <ul>
                        <li>
                          <i className="bi bi-chevron-right"></i>
                          Pembuatan Sistem Peminjaman Buku Mandiri / Self
                          Service ( Pilot Project)
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="resume-item">
                  <h4>Staff Officer - Internship</h4>
                  <h5>Juli 2017 - September 2017</h5>
                  <p>
                    <em>PT Telkom Indonesia (WITEL Makassar)</em>
                  </p>
                  <b>Deskripsi Pekerjaan</b>
                  <div className="row pt-4">
                    <div className="col">
                      <ul>
                        <li>
                          <i className="bi bi-chevron-right"></i> Melakukan
                          Follow Up Data Pelanggan dan Calon Pelanggan Indihome
                        </li>
                        <li>
                          <i className="bi bi-chevron-right"></i> Melakukan
                          Clustering/Mapping Area Pengembangan Jaringan FTTH
                          Wilayah Makassar
                        </li>
                        <li>
                          <i className="bi bi-chevron-right"></i> Melakukan
                          Survey Micro Demand Pengembangan Jaringan FTTH Wilayah
                          Makassar
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="portfolio" className="portfolio section-bg">
          <div className="container">
            <div className="section-title">
              <h2>Portofolio</h2>
            </div>
            <div className="row aos-init" data-aos="fade-up">
              <div className="col-lg-12 d-flex justify-content-center">
                <ul id="portfolio-flters">
                  <li data-filter="*" className="filter-active">
                    All
                  </li>
                  <li data-filter=".filter-web">Web</li>
                  <li data-filter=".filter-app">Mobile</li>
                  <li data-filter=".filter-card">UI/UX Design</li>
                </ul>
              </div>
            </div>
            <Row>
              {portfolioDatas.map((value, index) => (
                <Col key={index} xs={12} sm={6} md={4} lg={3}>
                  <Card className="mobile-card">
                    <ImageCard name={value.label} image={value.src} />
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </section>

        <section id="contact" className="contact">
          <div className="container">
            <div className="section-title">
              <h2>Kontak</h2>
            </div>

            <div className="col-12 d-flex align-items-stretch">
              <div className="info">
                <div className="address">
                  <i className="bi bi-geo-alt"></i>
                  <h4>Alamat:</h4>
                  <p>Gowa, Sulawesi Selatan</p>
                </div>

                <div className="email">
                  <i className="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <p>
                    <a
                      href="mailto:zhulachmad@gmail.com"
                      className="__cf_email__"
                    >
                      zhulachmad@gmail.com
                    </a>
                  </p>
                </div>

                <div className="phone">
                  <i className="bi bi-phone"></i>
                  <h4>Call:</h4>
                  <p>+6281342067461</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
